function initSW() {
    if (!("serviceWorker" in navigator)) {
        console.error("Service Worker is not supported");
        return Promise.reject("Service Worker not supported");
    }

    let publicPath = process.env.WEB_PUSH_PATH || `${process.env.APP_URL}/js/serviceWorker.js`;


    return navigator.serviceWorker
        .register(publicPath)
        .then((registration) => {
            return new Promise((resolve, reject) => {
                if (registration.installing) {
                    registration.installing.addEventListener(
                        "statechange",
                        function () {
                            if (this.state === "activated") {
                                resolve(registration);
                            }
                        }
                    );
                } else if (registration.waiting) {
                    reject("Service Worker is waiting");
                } else if (registration.active) {
                    resolve(registration);
                }
            });
        })
        .then((registration) => {
            return registration;
        })
        .catch((err) => {
            console.error(
                "Service Worker registration or activation failed:",
                err
            );
            throw err;
        });
}

function initPush(registration) {
    if (!registration.pushManager) {
        console.error("Push manager not found");
        return Promise.reject("Push manager not found");
    }

    return registration.pushManager
        .getSubscription()
        .then((subscription) => {
            // console.log(`Existing push subscription found: ${subscription}`);
            // if (subscription) {
            //     console.log("Existing push subscription found:", subscription);
            //         // subscription.unsubscribe().then((success) => {
            //         //     console.log("Unsubscribed from existing push subscription:", success);
            //         // });
            //     return subscription;
            // } else {
                // console.log("No push subscription found, creating new one");
                return subscribeUser(registration);
            // }
        })
        .catch((error) => {
            console.error("Error in initPush:", error);
            throw error;
        });
}

function subscribeUser(registration) {
    const vapidPublicKey = process.env.VAPID_PUBLIC_KEY; // Replace with your actual VAPID public key

    if (!vapidPublicKey) {
        console.error('VAPID_PUBLIC_KEY is not set');
        return Promise.reject('VAPID_PUBLIC_KEY is not set');
    }

    const subscribeOptions = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(vapidPublicKey)
    };

    return registration.pushManager.subscribe(subscribeOptions)
        .then((pushSubscription) => {
            return storePushSubscription(pushSubscription);
        })
        .catch((err) => {
            console.error('Failed to subscribe the user:', err);
            if (Notification.permission === 'denied') {
            } else {
                console.error('Unable to subscribe to push', err);
            }
            throw err;
        });
}

function urlBase64ToUint8Array(base64String) {
    var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    var base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function storePushSubscription(pushSubscription) {
    const token = document.querySelector('meta[name=csrf-token]').getAttribute('content');

    // Uncomment and set the appropriate URL for your backend API to store the subscription
    return fetch(`${process.env.APP_URL}/push`, {
        method: "POST",
        body: JSON.stringify(pushSubscription),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": token,
        },
    })
    .then((res) => {
        if (!res.ok) {
            throw new Error("Failed to store push subscription");
        }
        return res.json();
    })
    .then((data) => {
        return data;
    })
    .catch((err) => {
        console.error("Error storing push subscription:", err);
        throw err;
    });
}

document.addEventListener("DOMContentLoaded", (event) => {
    initSW()
        .then((registration) => {
            return initPush(registration);
        })
        .then(() => {
        })
        .catch((error) => {
            console.error(
                "Error setting up service worker or push notifications:",
                error
            );
        });
});
